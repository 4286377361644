import React, { Component } from 'react';
import {NavLink, Link} from 'react-router-dom'
import Form from './Form'
import InfoHours from '../../react/InfoHours';
import './coral.scss'
class CoralCafe extends Component {
  render() {
    return (
      <div className="coralcafe">
      </div>
    );
  }
}

export const pages = [

  // {path: "menu", label: "Menu" },
  {path: "/order", label: "Order" },
  {path: "/signup", label: "", hidden: true, component: Form },
  // {path: "https://nohoramenkitchenla.com/contact/", label: "Contact", external: true },

]

export function SiteNav () {
    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      {/* <ThemeToggle></ThemeToggle> */}
    </nav>
  )
}


export class Header extends Component {
  render() {
    return (
      <div className="order-benefits-wrapper">
        {/* <div className="john">
          <div className="picture">
            <img src="https://afag.imgix.net/coral-cafe/john.jpg?w=800&auto=format" alt="John Leousis in front of coral cafe" />
          </div>

          <div className="text">
            <p>It is with a heavy heart that we inform all our customers that co owner John Leousis passed away.</p>
            <p>In his memory Coral Cafe will be closed Monday the 22nd and Tuesday the 23rd. We will reopen Wednesday the 24th for normal hours 7am to 11pm</p>
            <p>If you wish you may <Link to="/signup">leave us a message</Link>.</p>
          </div>

          <br style={{
            clear: "left"
          }}
          />
        </div> */}
        <div className="order-benefits">

          <div className="text">
            <p>Order below for Togo & Delivery</p>
            <p>All Week: <br/>7am - 10:30pm</p>
            <div style={{textAlign: "right"}}>
          
              <a
                style={{color: "white"}}
                href="/public/coral-cafe-menu-2024.pdf">pdf menu</a>
            </div>
          </div>

         



        </div>
        {/* <InfoHours></InfoHours> */}
      </div>
    );
  }
}

export default CoralCafe;
