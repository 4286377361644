
import * as Components from "./Coralcafe"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "coralcafe"
}

